import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import SEO from 'react-seo-component'

import pricingPageStyle from "assets/jss/material-kit-react/views/pricingPage.jsx";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

class DocumentationPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    const headersString = `
    headers: {
      'Content-Type': 'application/json',
      'Api-Key': YOUR_API_KEY
    }    
    `;
    const domainJsString = `
    fetch("https://api.categle.com/v1/domain/cnn.com", 
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Api-Key': YOUR_API_KEY
          } 
        })
    `;
    const domainCurlString = `
      curl \\
        -H 'Content-Type: application/json' \\
        -H 'Api-Key: YOUR_API_KEY' \\
        "https://api.categle.com/v1/domain/cnn.com"
    `;  
    const pageJsString = `
    fetch("https://api.categle.com/v1/page/www.categle.com/documentation/", 
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Api-Key': YOUR_API_KEY
          } 
        })
    `;
    const pageCurlString = `
      curl \\
        -H 'Content-Type: application/json' \\
        -H 'Api-Key: YOUR_API_KEY' \\
        "https://api.categle.com/v1/page/www.categle.com/documentation/"
    `;
    const resultString = `
    {
      "assessment":[
        {
          "category":1412,
          "confidence":79
        },
        {
          "category":700,
          "confidence":62
        }
      ]
    }
    `;
    const siteTitle = "Categorization API documentation";
    const description = "Categle API documentation. How to categorize domain name and web pages using Categle API."
    const siteName = "Categle"       
    return (
      <div>
        <SEO 
            title={siteTitle} 
            description={description} 
            titleTemplate={siteName} 
            author="Categle" />           
        <Header
          color="transparent"
          brand="Categle.com"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white"
          }}
          {...rest}
        />
        <Parallax small filter />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div>
            <div className={classes.container}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div>
                    <div className={classes.name}>
                      <h1>Documentation</h1>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
              <div className={classes.description}>
                <p>
                    <h2>API Key</h2>
                    To make a request to Categle, you need to provide an API key in the request header.
                    <br />
                    You will find your API key in your <a href="/dashboard/#/app/keys">dashboard</a>.

                    <SyntaxHighlighter language="javascript" style={dark}>
                      {headersString}
                    </SyntaxHighlighter>  

                    <h2>Categories</h2>
                    You can find the list of all Categle categories <a href="/documentation/categories/">here</a>

                    <h2>Categorize domain API call</h2>
                    To categorize a domain you need to make a GET request to <i>https://api.categle.com/v1/domain/DOMAIN_NAME_TO_TEST</i>
                    <br />
                    For example, to categorize domain <i>cnn.com</i>, execute the following command using curl:
                    <SyntaxHighlighter language="powershell" style={dark}>
                      {domainCurlString}
                    </SyntaxHighlighter>
                    or using ReactJs:
                    <SyntaxHighlighter language="javascript" style={dark}>
                      {domainJsString}
                    </SyntaxHighlighter>                  

                    <h2>Categorize web page API call</h2>
                    Similarly, you can categorize an individual web page by making a 
                    GET request to <i>https://api.categle.com/v1/page/PAGE_URL_TO_TEST</i>

                    <br />
                    For example, to categorize web page <i>www.categle.com/documentation/</i>, execute the following command using curl:
                    <SyntaxHighlighter language="powershell" style={dark}>
                      {pageCurlString}
                    </SyntaxHighlighter>
                    or using ReactJs:
                    <SyntaxHighlighter language="javascript" style={dark}>
                      {pageJsString}
                    </SyntaxHighlighter>

                    <h2>Categorization response</h2>
                    Both Domain and Web page categorization requests return result in the same format, here is an example of it:
                    <SyntaxHighlighter language="powershell" style={dark}>
                      {resultString}
                    </SyntaxHighlighter>

                    For each categorization request the caller gets a list of categories with the confidence level of the categorization.
                    <br />
                    You can find the list of all Categle category codes <a href="/documentation/categories/">here</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(pricingPageStyle)(DocumentationPage);
