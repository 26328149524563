import React from "react";

import "assets/scss/material-kit-react.scss?v=1.4.0";
import 'typeface-roboto';
import 'typeface-roboto-slab';
// pages for this product
import DocumentationPage from "../../components/DocumentationPage/DocumentationPage.jsx";

export default () => (
  <DocumentationPage />
);
